import Song   from './Song';

import africa from './audio/africa.mp3';
import './tailwind.output.css';


function App() {
  return (
    <>
      <Song source={africa} />
      <Song source={africa} />
      <Song source={africa} />
    </>
  );
}

export default App;
