import { useState } from 'react';
import ReactWaves   from '@dschoon/react-waves';

import './tailwind.output.css';


function Song({ source }) {
  const [playing, setPlaying] = useState(false);
  return (
    <div className="flex items-center">
        <div
          className=""
          onClick={() => {
            setPlaying(!playing);
          }}
        >
          {!playing ? '▶' : '■'}
        </div>
        <ReactWaves
          audioFile={source}
          className={'xxx'}
          options={{
            barHeight: 2,
            cursorWidth: 0,
            fillParent: true,
            height: 50,
            hideScrollbar: true,
            progressColor: '#EC407A',
            waveColor: '#D1D6DA',
            barGap: 2,
            barWidth: 3,
            responsive: true,
            backend: 'MediaElement',
          }}
          volume={1}
          zoom={1}
          playing={playing}
        />
    </div>
  );
}

export default Song;
